<template>
  <div v-if="prevFormData">
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Propuesta de Valor
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('general-data')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ prevFormData.valueProposal }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Entregables
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('general-data')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ prevFormData.deliverables }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Presupuesto
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('general-data')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ prevFormData.budget }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Oferta Económica
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('general-data')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto" v-if="prevFormData.economicalOffer">
        {{ prevFormData.economicalOffer.name }} ({{ prevFormData.economicalOffer.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.economicalOffer" class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Duración del Proyecto
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('general-data')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div
        class="text-profile roboto"
        v-if="prevFormData.period && prevFormData.period.endDate && prevFormData.period.startDate"
      >
        {{ ProjectPeriod(prevFormData.period.endDate.split("T")[0], prevFormData.period.startDate.split("T")[0]) }}
      </div>
      <div v-else class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Procesos y Estrategia
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('optimization')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div v-if="prevFormData.processAndStrategyFile" class="text-profile roboto">
        {{ prevFormData.processAndStrategyFile.name }} ({{ prevFormData.processAndStrategyFile.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.processAndStrategyFile" class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Cronograma y Plan de Trabajo
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('optimization')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div v-if="prevFormData.workplan" class="text-profile roboto">
        {{ prevFormData.workplan.name }} ({{ prevFormData.workplan.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.workplan" class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Consideraciones Generales
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('optimization')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div v-if="prevFormData.generalConsiderations" class="text-profile roboto">
        {{ prevFormData.generalConsiderations.name }} ({{ prevFormData.generalConsiderations.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.generalConsiderations" class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Equipo
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('team')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ prevFormData.teamDescription }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Características detallas del Equipo
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('team')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div
        v-show="prevFormData.teamCharacteristics"
        class="text-profile roboto mb-1"
        v-for="file in prevFormData.teamCharacteristics"
        :key="file.name"
      >
        {{ file.name }} ({{ file.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.teamCharacteristics" class="text-profile roboto" />
    </v-card>
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Anexos
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('team')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div
        v-show="prevFormData.aditionalAttachments"
        class="text-profile roboto mb-1"
        v-for="file in prevFormData.aditionalAttachments"
        :key="file.name"
      >
        {{ file.name }} ({{ file.size / 1000000 }}
        MB)
      </div>
      <div v-if="!prevFormData.aditionalAttachments" class="text-profile roboto" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Confirmation",
  mounted() {
    this.setInProgress(this.stepIndex);
    this.prevFormData = { ...this.getPrevFormData };
    let status = 1;
    if (this.getTenderRequest.status) {
      status = this.getTenderRequest.status.id;
    }
    if (status < 3) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  },
  updated() {
    if (this.valid) {
      this.setComplete(this.stepIndex);
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
    } else {
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
      this.setInProgress(this.stepIndex);
    }
  },
  data() {
    return {
      stepIndex: 3,
      prevFormData: null,
      formData: {
        informationDeclaration: false,
        authorizationDeclaration: false,
        termsAndConditions: false,
      },
      //valid: false
      valid: true,
      moment: moment,
      edit: false,
    };
  },
  methods: {
    ...mapActions("applyForProject", ["setInProgress", "setComplete", "setStepData"]),
    goToStep(step) {
      this.$router.push(step);
    },
    ProjectPeriod(endDate, startDate) {
      let end = moment(endDate);
      let start = moment(startDate);
      let PeriodDate = "";
      if (end && start) {
        let years = end.diff(start, "year");
        start.add(years, "years");
        let months = end.diff(start, "months");
        start.add(months, "months");
        let days = end.diff(start, "days");
        if (years > 0) {
          if (years > 1) {
            years = years + " Años ";
          } else {
            years = years + " Año ";
          }
        } else {
          years = "";
        }
        if (months > 0) {
          if (months > 1) {
            months = months + " Meses ";
          } else {
            months = months + " Mes ";
          }
        } else {
          months = "";
        }
        if (days > 0) {
          if (days > 1) {
            days = days + " Dias ";
          } else {
            days = days + " Dia";
          }
        } else {
          days = "";
        }
        PeriodDate = years + months + days;
      }
      return PeriodDate;
    },
  },
  computed: {
    ...mapGetters({
      getPrevFormData: "applyForProject/getFormData",
      getTenderRequest: "applyForProject/getTenderRequest",
    }),
  },
};
</script>

<style scoped>
.v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}

.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

.terms-and-conditions >>> .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.edit-icon {
  cursor: pointer;
}
</style>
